import React from 'react'

function SudamericaIcon() {
  return (  
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="134.000000pt" height="134.000000pt" viewBox="0 0 134.000000 134.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,134.000000) scale(0.100000,-0.100000)"
    fill="#ffffff" stroke="none">
    <path d="M380 1329 c-44 -18 -60 -40 -60 -81 0 -23 -8 -50 -20 -66 -22 -30 -60 -131 -60 -161 0 -11 11 -32 24 -48 12 -15 35 -48 51 -73 15 -25 49 -67 75 -95 l48 -50 5 -280 c4 -305 12 -354 63 -415 37 -43 75 -60 137 -60 47 0 57 4 70 24 22 32 11 65 -31 102 -34 29 -35 33 -24 58 14 32 16 76 3 76 -27 0 18 119 45 120 20 0 64 41 64 58 0 9 16 30 35 45 38 30 68 82 80 137 9 41 30 59 67 60 39 0 66 34 78 99 11 57 33 108 55 126 19 16 24 67 10 96 -26 50 -82 87 -170 115 -56 17 -85 31 -85 41 0 30 -41 71 -97 97 -32 15 -71 35 -88 44 -26 14 -74 22 -210 37 -22 3 -51 0 -65 -6z m230 -130 c125 -56 135 -64 132 -96 -4 -38 8 -46 92 -67 71 -17 140 -48 151 -67 4 -6 -2 -21 -14 -33 -13 -13 -21 -36 -22 -57 0 -19 -5 -49 -12 -68 -10 -28 -20 -36 -58 -47 -56 -16 -71 -34 -88 -99 -15 -63 -35 -93 -69 -108 -34 -15 -51 -36 -52 -64 0 -17 -7 -22 -32 -25 -24 -2 -34 -9 -37 -23 -2 -11 -8 -23 -14 -26 -13 -9 -25 -86 -27 -169 -2 -59 -3 -62 -12 -35 -6 17 -13 158 -15 323 l-5 293 -36 27 c-33 25 -142 180 -142 202 0 6 14 24 30 42 25 25 30 39 30 78 0 26 3 49 6 52 14 15 134 -6 194 -33z"/>
    </g>
  </svg>
  )
}

export default SudamericaIcon
