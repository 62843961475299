const vaccinePhasesData = {
  hexColors: [
    "#00d6b4", //green
    "#ffd600", //yellow
    "#11cdef", //teal
    "#e14eca", //pink
    "#fb6340", //orange
    "#1d8cf8", //blue
    "#8965e0", //purple
    "#f5365c", //red
  ]
}


module.exports = {
  vaccinePhasesData
}