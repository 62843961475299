import React from 'react'

function VaccineIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
    <path d="M31.7249 5.13548L26.8636 0.274105C26.4975 -0.0920195 25.9039 -0.0920195 25.5378 0.274105C25.1717 0.64023 25.1717 1.23379 25.5378 1.59992L25.9797 2.04185L22.0022 6.01935L20.0135 4.0306C19.6474 3.66448 19.0538 3.66448 18.6877 4.0306C18.3215 4.39673 18.3215 4.99029 18.6877 5.35642L18.9087 5.57742L7.02823 17.4579C6.00636 18.4797 5.93717 20.0985 6.81986 21.2018L4.81854 23.2031C4.45242 23.5692 4.45242 24.1628 4.81854 24.5289L5.48148 25.1918L0.274105 30.3991C-0.0920195 30.7652 -0.0920195 31.3588 0.274105 31.7249C0.64023 32.091 1.23379 32.091 1.59992 31.7249L6.80723 26.5176L7.47017 27.1805C7.83629 27.5467 8.42986 27.5467 8.79598 27.1805L10.7973 25.1792C11.9005 26.0619 13.5194 25.9927 14.5412 24.9708L26.4216 13.0905L26.6426 13.3115C27.0087 13.6776 27.6023 13.6776 27.9684 13.3115C28.3345 12.9454 28.3345 12.3518 27.9684 11.9857L25.9797 9.99692L29.9572 6.01942L30.3991 6.46135C30.7652 6.82748 31.3588 6.82748 31.7249 6.46135C32.091 6.09523 32.091 5.5016 31.7249 5.13548V5.13548ZM18.2717 8.86598L20.2344 6.90329L22.0022 8.67104L20.0395 10.6337L18.2717 8.86598ZM8.13311 25.1918L6.80729 23.866L8.13311 22.5402L9.45892 23.866L8.13311 25.1918ZM13.2154 23.645C12.8499 24.0106 12.2551 24.0106 11.8896 23.645L8.35411 20.1095C7.98854 19.7439 7.98854 19.1492 8.35411 18.7837L8.99098 18.1468L9.65392 18.8097C10.02 19.1759 10.6136 19.1759 10.9797 18.8097C11.3459 18.4436 11.3459 17.85 10.9797 17.4839L10.3168 16.821L11.6426 15.4952L12.3055 16.1581C12.6717 16.5242 13.2652 16.5242 13.6314 16.1581C13.9975 15.792 13.9975 15.1984 13.6314 14.8323L12.9685 14.1694L14.2943 12.8435L14.9572 13.5065C15.3234 13.8726 15.9169 13.8726 16.283 13.5065C16.6492 13.1404 16.6492 12.5468 16.283 12.1807L15.6201 11.5177L16.9459 10.1919L21.8073 15.0533L13.2154 23.645ZM23.1331 13.7274L21.3654 11.9596L23.328 9.99692L25.0958 11.7647L23.1331 13.7274ZM24.6539 8.67104L23.328 7.34523L27.3055 3.36773L28.6314 4.69354L24.6539 8.67104Z" fill="white"/>
    </g>
    </svg>
  )
}

export default VaccineIcon
